<template>
  <div>
    <h1>Võrdlustabeli reeglid</h1>
    <v-btn
      v-show="true"
      dark
      absolute
      top
      right
      @click="addNewCondition"
    >
      Lisa uus võrdlus
    </v-btn>
    <v-data-table
      :headers="headers"
      :items="conditions"
      item-key="guid"
      :loading="loading"
      loading-text="Laeb... palun oota"
      :hide-default-footer=true
      :items-per-page="30"
      class="mb-4"
      @click:row="handleClick"
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editCondition(item)"
        >
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import axios from "axios";

  export default {
    name: "Conditions",
    data() {
      return {
        loading: true,
        headers: [
          { text: 'Pealkiri', value: 'title' },
          { text: 'Kindlustusliik', value: 'insurance_type' },
          { text: '', value: 'actions', sortable: false }
        ],
        conditions: []
      }
    },
    mounted() {
      this.fetchConditions();
    },
    methods: {
      addNewCondition: function () {
        this.$router.push('/conditions-new');
      },
      fetchConditions: function () {
        this.loading = true;
        this.conditions = [];
        let url = axios.defaults.baseURL + '/api/conditions';

        axios.get(url).then((response) => {
          this.conditions = response.data;
          this.loading = false;
        });
      },
      editCondition: function (item) {
        this.$router.push('/condition/' + item.id);
      },
      handleClick: function (item) {
        this.$router.push('/condition/' + item.id);
      }
    }
  }
</script>
